.chat-container {
  display: flex;
  flex-direction: column;
  height: 60vh;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.user {
  background-color: lightblue;
  align-self: flex-end;
}

.bot {
  background-color: lightgray;
  align-self: flex-start;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  margin-right: 10px;
}

.chat-input button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: lightblue;
  border: none;
  cursor: pointer;
}
.code-summary {
  margin-top: 20px;
}

.toggle-button {
  background-color: blue;
  border: none;
  color: #555;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}

.summary-content {
  background-color: #f8f8f8;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  line-height: 1.5;
}
