.search-bar {
  margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .data-table {
    border-collapse: collapse;
    width: 100%;
    
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: 12px;
  }
  
  .data-table th {
    background-color: #1A73E8;
    color:#fff;
  }
  
  .total-pages {
    margin-top: 10px;
    color:royalblue
  }
  
  .previous-button,
  .next-button {
    padding: 8px 16px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .previous-button:hover,
  .next-button:hover {
    background-color: #45a049;
  }
  
  .previous-button:disabled,
  .next-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  .md_button{
    margin: 30px;
  }
  .pagination-buttons {
   
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
   
  }

  
  .selected-items {
    margin-top: 20px;
  }
  
  .selected-items p {
    font-weight: bold;
  }
  
  .selected-items ul {
    list-style: none;
    padding: 0;
  }
  
  .selected-items li {
    margin-bottom: 5px;
  }
  
  .data-table input[type="checkbox"] {
    margin-right: 5px;
  }
  .date-picker {
    display: block;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  